import Box, { BoxProps } from '@material-ui/core/Box';

import Divider from '@material-ui/core/Divider';
import { Feature } from 'geojson';
import Link from '@material-ui/core/Link';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import sortBy from 'lodash.sortby';
import moment from 'moment';

export interface Props extends BoxProps {
  features: Feature[];
}

const useStyles = makeStyles(theme => ({
  title: {
    marginTop: 0,
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2), // Leave room for close button
    textTransform: 'uppercase',

    '& + div': {
      // First prop value line
      // Some features will not have props (ex. route features)
      marginTop: theme.spacing(2)
    }
  },
  key: {
    flex: '1 0 auto',
    marginRight: theme.spacing(2)
  }
}));

const formatProperties = (properties: any) => {
  const values = [];

  // Build array (`values`) of all property/value pairs to render
  for (const property in properties) {
    const value = properties[property];

    // Date value in UTC
    if (
      /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/.test(
        value
      )
    ) {
      values.push({ key: property, value: moment.utc(value).format('L') });
      continue;
    }

    // Ignore any properties starting with "_"
    if (!/^_/.test(property)) {
      values.push({ key: property, value });
    }

    // Add `links` for property keys prefixed with '_link'
    if (/_link.*/.test(property)) {
      values.push({
        link: value,
        // File name without directories or SAS token
        linkText: value
          .split('/')
          .slice(-1)[0]
          .split('?')[0]
      });
    }
  }

  return values;
};

const MapPopup: React.FC<Props> = ({ features, ...other }) => {
  const classes = useStyles();

  const sortedFeatures = sortBy(features, 'properties._title');

  return (
    <Box px={2} {...other}>
      {sortedFeatures.map((feature: any, index: number) => (
        <React.Fragment key={index}>
          <Box pt={2} pb={1}>
            <Typography
              className={classes.title}
              variant="h3"
              color="secondary"
              align="center"
            >
              {feature.properties._title}
            </Typography>
            {formatProperties(feature.properties).map((v, i) => (
              <Box key={i} display="flex" flexDirection="row" my={1}>
                {v.value !== undefined && (
                  <>
                    <Typography className={classes.key} component="span">
                      {v.key}
                    </Typography>
                    <Typography variant="caption" align="right">
                      {v.value}
                    </Typography>
                  </>
                )}
                {v.link !== undefined && (
                  <Link href={v.link}>{v.linkText}</Link>
                )}
              </Box>
            ))}
          </Box>
          {index < features.length - 1 && <Divider variant="fullWidth" />}
        </React.Fragment>
      ))}
    </Box>
  );
};

export default MapPopup;
