import { State } from './reducer';

const initialState: State = {
  mapLayers: [
    {
      key: 'centerline',
      title: 'Centerline',
      isChecked: true,
      controlledLayerIds: ['centerline', 'centerline-label']
    },
    {
      key: 'route',
      title: 'Route',
      isChecked: true,
      controlledLayerIds: ['route', 'route-label']
    },
    {
      key: 'ownership',
      title: 'Ownership',
      isChecked: true,
      controlledLayerIds: ['ownership', 'ownership-outline']
    },
    {
      key: 'parcels',
      title: 'Parcels',
      isChecked: true,
      controlledLayerIds: ['parcels', 'parcels-outline']
    }
  ],
  mapLocation: {
    center: [-96.69, 28.217],
    zoom: 6
  },
  mapPopupLayers: ['centerline', 'ownership', 'parcels', 'route']
};

export default initialState;
